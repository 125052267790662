.swiper-button-prev,
.swiper-button-next {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: rgba(215, 215, 225, 0.65);
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 22px;
  color: rgba(0, 0, 0, 0.56);
}

.swiper-button-disabled {
  display: none;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  background-color: rgba(225, 225, 230, 0.7);
}

.swiper-button-prev:hover:after,
.swiper-button-next:hover:after {
  color: rgba(0, 0, 0, 0.65);
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 8px;
}

.swiper-pagination-bullet {
  background-color: theme("colors.gray.500");
  opacity: 0.3;
}

.swiper-pagination-bullet-active {
  opacity: 1;
}
