.slick-dots {
  display: flex !important;
  justify-content: center;
  align-items: center;
  justify-content: center;
}

.slick-dots li {
  margin: 0;
  width: auto;
  height: auto;
}

.slick-dots li button {
  margin: 0;
  padding: 7px;
  width: 22px;
  height: 22px;
}

.slick-dots li button::before {
  content: "\A";
  left: 7px;
  top: 7px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: theme("colors.gray.500");
}
