.compass {
  position: relative;
}
.compass__windrose {
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: theme("colors.gray.700");
  border: 4px solid theme("colors.slate.800");
  position: relative;
  box-shadow: inset 0 0 5px 3px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.compass__windrose:before {
  top: -9px;
  left: calc(50% - 9px);
  width: 18px;
  height: 18px;
  border-radius: 1000% 50% 0 50%;
  transform: rotate(45deg);
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.05);
  z-index: 1;
}
.compass__windrose:before {
  content: "";
  position: absolute;
  background-color: theme("colors.slate.800");
}

.compass__mark,
.compass__mark--direction-h,
.compass__mark--direction-v {
  width: 4px;
  height: 100%;
  left: calc(50% - 2px);
  position: absolute;
}

.compass__mark:before,
.compass__mark--direction-h:before,
.compass__mark--direction-v:before,
.compass__mark:after,
.compass__mark--direction-h:after,
.compass__mark--direction-v:after {
  content: "";
  position: absolute;
  left: 0;
  width: 4px;
  height: 4px;
  border-radius: 50px;
  background-color: theme("colors.gray.600");
}
.compass__mark:before,
.compass__mark--direction-h:before,
.compass__mark--direction-v:before {
  top: 12%;
}
.compass__mark:after,
.compass__mark--direction-h:after,
.compass__mark--direction-v:after {
  bottom: 12%;
}
.compass__mark--direction-h:before,
.compass__mark--direction-v:before,
.compass__mark--direction-h:after,
.compass__mark--direction-v:after {
  width: auto;
  height: auto;
  font-size: 14px;
  line-height: 14px;
  border-radius: 0;
  background: transparent;
  color: #fff;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.35);
}
.compass__mark--direction-h:before,
.compass__mark--direction-v:before {
  top: 10%;
}
.compass__mark--direction-h:after,
.compass__mark--direction-v:after {
  bottom: 10%;
  transform: rotate(180deg);
}
.compass__mark--direction-h:before {
  content: "N";
  left: 50%;
  transform: translateX(-50%);
  font-weight: 600;
  font-size: 17px;
}
.compass__mark--direction-h:after {
  content: "S";
  left: 50%;
  transform: translateX(-50%);
}
.compass__mark--direction-v {
  transform: rotate(90deg);
}
.compass__mark--direction-v:before {
  content: "E";
  left: 50%;
  transform: translateX(-50%);
}
.compass__mark--direction-v:after {
  content: "W";
  left: 50%;
  transform: translateX(-50%);
}
.compass__mark:nth-child(1) {
  transform: rotate(15deg);
}
.compass__mark:nth-child(2) {
  transform: rotate(30deg);
}
.compass__mark:nth-child(3) {
  transform: rotate(45deg);
}
.compass__mark:nth-child(4) {
  transform: rotate(60deg);
}
.compass__mark:nth-child(5) {
  transform: rotate(75deg);
}
.compass__mark:nth-child(6) {
  transform: rotate(105deg);
}
.compass__mark:nth-child(7) {
  transform: rotate(120deg);
}
.compass__mark:nth-child(8) {
  transform: rotate(135deg);
}
.compass__mark:nth-child(9) {
  transform: rotate(150deg);
}
.compass__mark:nth-child(10) {
  transform: rotate(165deg);
}
.compass__mark:nth-child(11) {
  transform: rotate(180deg);
}
.compass__arrow-container {
  width: 48.076923%;
  height: 48.076923%;
  border-radius: 50%;
  background-color: theme("colors.slate.800");
  box-sizing: border-box;
  top: 50%;
  left: 50%;
  position: absolute;
  z-index: 2;
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease;
}
.compass__arrow {
  width: 71%;
  height: 71%;
  margin-left: 14.5%;
  margin-top: 14.5%;
  background-color: theme("colors.slate.900");
  border-radius: 0 50% 50% 50%;
  box-sizing: border-box;
  transform: rotate(45deg);
}
.compass__labels {
  position: absolute;
  z-index: 1;
  background-color: theme("colors.slate.900");
  width: 40px;
  height: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  box-sizing: border-box;
}
.compass__labels > span {
  display: inline-block;
  width: 100%;
  text-align: center;
  color: white;
}
.compass__labels > span:first-child {
  font-size: 17px;
  line-height: 17px;
  font-weight: 500;
}
.compass__labels > span:last-child {
  font-size: 14px;
  line-height: 14px;
  padding-left: 7px;
}
