.gradient-background {
  background: linear-gradient(
    201deg,
    theme("colors.blue.500"),
    theme("colors.purple.500"),
    theme("colors.orange.500"),
    theme("colors.yellow.500")
  );
  background-size: 300% 300%;
  animation: gradient-animation 40s ease infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
