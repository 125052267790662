.noUi-target {
  border: none;
  box-shadow: none;
  background-color: transparent;
}

.noUi-horizontal {
  height: 55px;
}

.noUi-connects {
  border: none;
  background: theme("colors.gray.300");
  border-radius: 0.75rem;
}

.noUi-connect {
  /* ACTIVE */
  background: theme("colors.blue.500");
  border-radius: 0;
}

.noUi-horizontal .noUi-handle {
  position: absolute;
  height: 28px;
  top: 12px;
  background-color: theme("colors.white");
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border: none;
}

.noUi-handle::before {
  top: 7px;
  background: theme("colors.gray.500");
}

.noUi-handle::after {
  top: 7px;
  background: theme("colors.gray.500");
}

.noUi-horizontal .noUi-tooltip {
  bottom: 180%;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  color: theme("colors.black");
}

.noUi-pips.noUi-pips-horizontal {
  height: calc(100% - 12px);
}

.noUi-tooltip {
  display: none;
}

.noUi-active .noUi-tooltip {
  display: block;
}

.noUi-value {
  margin-top: 6px;
  font-size: theme("fontSize.xs");
  color: theme("colors.black");
}

.noUi-marker-normal {
  background: theme("colors.gray.400");
}

.noUi-marker-large {
  background: theme("colors.gray.500");
}

.noUi-ltr .noUi-value.noUi-value-horizontal.noUi-value-large:nth-child(2) {
  transform: translate(-2px, 50%);
}

.noUi-ltr .noUi-value.noUi-value-horizontal.noUi-value-large:last-child {
  transform: translate(calc(-100% + 2px), 50%);
}

.noUi-rtl .noUi-value.noUi-value-horizontal.noUi-value-large:nth-child(2) {
  transform: translate(2px, 50%);
}

.noUi-rtl .noUi-value.noUi-value-horizontal.noUi-value-large:last-child {
  transform: translate(calc(100% - 2px), 50%);
}
